import classNames from 'classnames';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const Modal = ({
    isOpen,
    toggleModalHandler,
    size = 'normal',
    children,
}) => {
    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('is-fixed');
        } else {
            document.body.classList.remove('is-fixed');
        }

        return () => {
            document.body.classList.remove('is-fixed');
        };
    }, [isOpen]);

    if (!isOpen) {
        return null;
    }

    return (
        <div
            className={classNames('modal', {
                'container-small': size === 'small'
            })}
        >
            <Icon
                className="close-btn"
                name="close"
                width={30}
                height={30}
                onClick={() => toggleModalHandler(false)}
            />
            <div className="inner">
                {children}
            </div>
        </div>
    );
};

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggleModalHandler: PropTypes.func.isRequired,
    size: PropTypes.oneOf(['normal', 'small']),
    children: PropTypes.node.isRequired
};

export default Modal;
