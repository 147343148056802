import classnames from 'classnames';
import formatContent from 'app/utilities/format-content';
import Icon from 'app/components/partials/icon';
import Modal from 'app/components/partials/modal';
import PropTypes from 'prop-types';
import YoutubePlayer from 'app/components/partials/youtube-player';
import React, { useState } from 'react';


const CallToAction = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { data, pageTitle } = props;
    const {
        content,
        file,
        file_alt: fileAlt,
        content_position: contentPosition,
        padding_top: paddingTop,
        padding_bottom: paddingBottom,
        video_url: videoUrl
    } = data;

    const mediaClass = classnames('call-to-action-media || preserve-aspect-ratio', {
        'left-aligned': contentPosition === 'right', // Aligns the media to the left position
        'is-video': videoUrl
    });

    const contentClass = classnames('call-to-action-content', {
        'right-aligned': contentPosition === 'right' // Aligns the content to the right position
    });

    const callToActionInnerClass = classnames('call-to-action-inner', {
        'padding-top': paddingTop === '1',
        'padding-bottom': paddingBottom === '1'
    });

    const handlePlayVideo = () => setIsModalOpen(true);

    return (
        <section className="call-to-action">
            <div className={callToActionInnerClass}>
                <div className={mediaClass} style={{ backgroundImage: `url(${file})` }}>
                    {videoUrl && <button className="play-button" onClick={handlePlayVideo}>
                        <Icon className="icon" name="play-button" title="Play video" width={80} height={80} />
                    </button>}
                    <div className="preserve-aspect-ratio-media">
                        <img className="fixed-image" src={file} alt={fileAlt || pageTitle} />
                    </div>
                </div>
                <div className={contentClass}>
                    <div className="inner || rich-editor-content" dangerouslySetInnerHTML={{ __html: formatContent(content, 'call-to-action') }}></div>
                </div>
            </div>

            <Modal toggleModalHandler={(isOpen) => setIsModalOpen(isOpen)} isOpen={isModalOpen}>
                <YoutubePlayer url={videoUrl} autoplay={0} />
            </Modal>
        </section>
    );
};

CallToAction.propTypes = {
    data: PropTypes.object.isRequired,
    pageTitle: PropTypes.string.isRequired
};

export default CallToAction;

